@font-face {
  font-family: "averta";
  src: url("assets/fonts/averta-black.eot");
  src: url("assets/fonts/averta-black.svg#proxima_nova_rgregular") format("svg"),
    url("assets/fonts/averta-black.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/averta-black.woff") format("woff"),
    url("assets/fonts/averta-black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "averta";
  src: url("assets/fonts/averta-bold.eot");
  src: url("assets/fonts/averta-bold.svg#proxima_nova_rgregular") format("svg"),
    url("assets/fonts/averta-bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/averta-bold.woff") format("woff"),
    url("assets/fonts/averta-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "averta";
  src: url("assets/fonts/averta-light.eot");
  src: url("assets/fonts/averta-light.svg#proxima_nova_rgregular") format("svg"),
    url("assets/fonts/averta-light.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/averta-light.woff") format("woff"),
    url("assets/fonts/averta-light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "averta";
  src: url("assets/fonts/averta-semibold.eot");
  src: url("assets/fonts/averta-semibold.svg#proxima_nova_rgregular") format("svg"),
    url("assets/fonts/averta-semibold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/averta-semibold.woff") format("woff"),
    url("assets/fonts/averta-semibold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  height: 100vh;
  font-family: "averta"
}
